import type { bottom } from '@popperjs/core';
<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

const currentResort: any = inject('currentResort')
const { currentPage } = usePageStore()
const currentYear = computed(() => new Date().getFullYear())

const footerSettings = computed(() => {
  if (currentResort)
    return currentResort.footer_settings
})

const isVisible = ref(false)
function visibilityChanged(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 100 > 0)
    isVisible.value = true
}

const hasOneSocialElement = computed(() => {
  if (!currentResort)
    return false

  return (
    currentResort.facebook_url
    || currentResort.youtube_url
    || currentResort.twitter_url
    || currentResort.instagram_url
    || currentResort.tiktok_url
  )
})
</script>

<template>
  <div v-if="currentResort" id="footer" name="footer" class="py-10 relative z-10 text-slate-600">
    <span v-voix-visibility="{ callback: visibilityChanged, threshold: [0, .25] }" />

    <div
      class="bottom bg-sanctuary-primary"
    >
      <SignUpForm
        form-id="A01904B0-3D23-4A65-B86A-EECBC4BD7A70"
        :language="currentPage?.language?.id"
      />
    </div>

    <div
      class="flex justify-between p-4 text-xs"
    >
      <div>
        <ul
          class="flex flex-col md:flex-row items-center list-reset"
        >
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/terms-conditions">
              Terms & Conditions
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/privacy-policy">
              Privacy Policy
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/accessibility">
              Accessibility
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a
              id="ot-sdk-btn" class="text-xs text-slate-600 ot-sdk-show-settings p-0"
              href="javascript:void(0)"
            >
              Cookie Settings
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://agentcashplus.com/">
              Travel Professionals
            </a>
          </li>
        </ul>
      </div>

      <div class="text-center">
        &copy; {{ currentYear }} Playa Management USA, LLC All rights reserved
      </div>
    </div>
  </div>
</template>
