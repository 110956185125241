<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import Container from '../blocks/Container.vue'
import Accordion from '../blocks/Accordion.vue'
import AccordionBody from '../blocks/AccordionBody.vue'
import Hamburger from './Hamburger.vue'
import AccordionHeader from './AccordionHeader.vue'
import AccordionItem from './AccordionItem.vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

const props = defineProps({
  currentResort: {
    type: Object,
    default: () => {},
  },
  resorts: {
    type: Array,
    required: true,
  },
})
const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const menuIsOpen = ref(false)
const scrollY = ref(0)
const hamburgler = ref(null)
const windowIsScrolled = computed(() => scrollY.value > 0)
const bookingWidgetStore = useBookingWidgetStore()
const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}

const menuItemsWithoutResorstsAndBrands = computed(() => {
  if (!props.currentResort?.menu)
    return []

  return props.currentResort.menu.menu_items.filter((item) => {
    return item.name !== 'Our Resorts' && item.name !== 'Our Brands'
  })
})

onMounted(() => {
  window.onscroll = () => {
    scrollY.value = window.scrollY
  }
})

const alphabetizedResorts = computed(() => {
  const resorts = JSON.parse(JSON.stringify(props.resorts))
  return resorts.sort((a: { name: string }, b: { name: string }) => {
    if (a.name < b.name)
      return -1
    if (a.name > b.name)
      return 1
    return 0
  })
})

const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'en')
})

const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'es')
})

function onLanguageChange(event) {
  console.log('hi')
  window.location.href = event.target.value
}
</script>

<template>
  <nav
    :class="{
      'scrolled': windowIsScrolled,
      'menu-open': menuIsOpen,
    }"
  >
    <div :class="{ 'bg-sanctuary-primary': menuIsOpen }">
      <Container class="z-10">
        <div class="font-sans flex items-center justify-between py-4 xs:px-4 md:py-5 md:px-0 xl:py-7">
          <div class="w-1/5 relative flex justify-start">
            <div class="bg-sanctuary-primary/25 blur-xl absolute inset-0" />
            <Hamburger ref="hamburgler" v-model="menuIsOpen" />
          </div>

          <div class="w-3/5 flex justify-center relative text-white">
            <a href="/" class="block relative z-10 w-[300px] h-[50px]">
              <template v-if="!menuIsOpen">
                <span :class="{ 'opacity-0': windowIsScrolled }" class="absolute inset-0 flex justify-center duration-200 -translate-y-4">
                  <LogosSanctuary id="logo" class="relative z-10 w-[90px] sm:w-[200px] h-[90px] text-white" :fill="false" />
                </span>
                <span :class="{ 'opacity-0': !windowIsScrolled }" class="absolute inset-0 flex justify-center duration-200">
                  <LogosSanctuary id="logo" class="w-[50px]" :fill="true" mode="submark" />
                </span>
              </template>
              <template v-else>
                <span class="absolute inset-0 flex justify-center duration-200 -translate-y-4">
                  <LogosSanctuary id="logo" class="relative z-10 w-[90px] sm:w-[200px] h-[90px] text-white" :fill="false" />
                </span>
              </template>
            </a>
          </div>

          <div class="w-1/5 flex items-center justify-end space-x-6 pr-2 md:pr-0">
            <select
              v-if="currentPage && currentPage.localizations.length"
              @input="onLanguageChange($event)"
              class="appearance-none row-start-1 col-start-1 bg-transparent border-none text-white text-xs font-light uppercase focus:border-transparent focus:outline-none"
            >
              <option :value="enLink?.path" :selected="currentPage.language_code === 'en'">
                English
              </option>
              <option :value="esLink?.path" :selected="currentPage.language_code === 'es'">
                Español
              </option>
            </select>

            <button
              type="submit"
              class="hidden md:block px-3 lg:px-6 py-2 lg:py-3 uppercase text-xs font-light text-white bg-sanctuary-tertiary border-sanctuary-prmary border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              @click="toggleBookingModal"
            >
              {{ $t('book-now') }}
            </button>
          </div>
        </div>

        <div class="flex justify-center">
          <div
            class="relative menu text-white flex-grow flex-col space-y-4 pt-12 pb-[100px] justify-start items-center overflow-y-auto"
            :class="{
              hidden: !menuIsOpen,
              flex: menuIsOpen,
            }"
          >
            <template v-if="currentResort?.menu">
              <div class="relative translate-x-12 flex flex-col space-y-4 z-20">
                <div
                  v-for="(item, menuKey) in menuItemsWithoutResorstsAndBrands"
                  :key="menuKey"
                  class="w-[300px] text-left"
                >
                  <NuxtLink v-if="item?.children.length === 0" :to="item.permalink" class="font-sans cursor-pointer font-normal pb-4 text-sanctuary-secondary hover:underline md:text-xl uppercase pl-7">
                    {{ item.name }}
                  </NuxtLink>
                  <Accordion v-else class="max-w-[858px] font-sans">
                    <AccordionItem :align-center="false" :index="1">
                      <AccordionHeader :align-center="false" show-plus-minus class="font-normal md:text-xl text-sanctuary-secondary">
                        {{ item.name }}
                      </AccordionHeader>
                      <AccordionBody>
                        <div
                          v-for="(childItem, itemKey) in item.children"
                          :key="itemKey"
                          class="mb-4"
                        >
                          <NuxtLink v-if="!childItem.permalink.startsWith('#')" :to="childItem.permalink" :target="childItem.target" class="text-center cursor-pointer font-extralight text-sm pb-4 text-sanctuary-secondary hover:underline md:text-xs uppercase">
                            {{ childItem.name }}
                          </NuxtLink>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div
                  v-if="currentResort.show_my_trip_link"
                  class="w-[300px] text-left cursor-pointer uppercase text-xs font-sans font-light text-sanctuary-secondary pl-7"
                >
                  <a href="#">
                    {{ $t("my-trips") }}
                  </a>
                </div>
                <div
                  v-if="currentResort.faq_link !== ''"
                  class="w-[300px] text-left cursor-pointer uppercase text-xs font-sans font-light text-sanctuary-secondary pl-7"
                >
                  <a :href="currentResort.faq_link">FAQ</a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </Container>
    </div>

    <div
      v-if="showBookingWidget" class="px-6 py-4 w-full relative"
      :class="{
        'bg-black': windowIsScrolled,
      }"
    >
      <FlightBookingForm class="lg:container" />
      <button class="p-4 absolute z-100 top-1 lg:top-0 -right-1 m-4 lg:m-0 pointer-events-auto" @click="toggleBookingModal">
        <Icon name="heroicons:x-mark-20-solid" class="text-gray lg:text-white w-8 h-8" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
:global(body:has(.menu-open)) {
  overflow: hidden;
}

nav {
  @apply fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in;

  .x-container {
    @apply flex flex-col h-full;
  }

  &.scrolled {
    @apply bg-sanctuary-primary shadow-xl text-white;
  }

  &::before {
    @apply block fixed top-0 left-0 right-0 h-0 w-full bg-sanctuary-primary z-[-1];
    content: '';
    background-image: url(/imgs/sanctuary/illustrations/feather-bg.png);
  }

  &.menu-open {
    @apply bg-transparent h-screen pb-0;

    &::before {
      @apply h-screen;
      animation: NavHeightAnimated 0.3s ease-in forwards;
    }

    .menu {

      /* HIDE SCROLLBARS: START */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      /* HIDE SCROLLBARS: END */

      .accordion {
        @apply w-full;
      }

      .logos {
        @apply grid grid-cols-1 gap-10 xs:grid-cols-2 md:grid-cols-3 md:gap-24;

        span {
          @apply border border-white/50 hover:bg-white/10 block px-2 py-1 font-light uppercase text-xs opacity-60 mx-auto w-max mt-4;
        }
      }
    }
  }
}

.feather-enter-active {
  @apply opacity-100 duration-1000 ease-out;
}

.feather-leave-active {
  opacity: 0;
}

.feather-enter-from {
  @apply opacity-0 translate-y-[50px];
}

@keyframes NavHeightAnimated {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
